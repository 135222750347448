import { GroupForumsService } from 'Roblox';
import groupModule from '../groupModule';

function groupWallNotice() {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      groupId: '<'
    },
    link(scope, element) {
      const renderDeprecationNotice = () => {
        GroupForumsService?.renderGroupWallDeprecationNotice(element[0], {
          groupId: scope.groupId
        });
      };

      element.ready(renderDeprecationNotice);

      ['groupId'].forEach(prop => {
        scope.$watch(prop, renderDeprecationNotice, true);
      });
    }
  };
}

groupModule.directive('groupWallNotice', groupWallNotice);

export default groupWallNotice;
