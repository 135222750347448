import groupModule from '../groupModule';

function groupWallService($filter, httpService, $q, groupsConstants, groupDetailsConstants) {
  'ngInject';

  return {
    sendPost(groupId, body, captchaData) {
      const config = {
        url: $filter('formatString')(groupDetailsConstants.urls.groupWallPosts, { id: groupId })
      };

      const data = {
        body
      };

      Object.assign(data, captchaData);

      return $q(function (resolve, reject) {
        httpService.httpPost(config, data).then(resolve, reject);
      });
    },

    deleteWallPost(groupId, postId) {
      const config = {
        url: $filter('formatString')(groupDetailsConstants.urls.deleteWallPost, {
          groupId,
          postId
        })
      };

      return httpService.httpDelete(config);
    },

    subscribeToWallNotifications(groupId) {
      const config = {
        url: $filter('formatString')(groupDetailsConstants.urls.subscribeToWallNotificatons, {
          groupId
        })
      };

      return httpService.httpPost(config);
    }
  };
}

groupModule.factory('groupWallService', groupWallService);
export default groupWallService;
