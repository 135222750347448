import { EnvironmentUrls } from 'Roblox';
import groupModule from '../groupModule';

function groupDetailsService(httpService, $filter, groupDetailsConstants, groupsConstants) {
  'ngInject';

  function sendGroupShout(groupId, message) {
    const config = {
      url: $filter('formatString')(groupDetailsConstants.urls.updateGroupStatus, { id: groupId })
    };

    const data = {
      message
    };
    return httpService.httpPatch(config, data);
  }

  function profilePageUrl(userId) {
    return `${EnvironmentUrls.websiteUrl}/users/${userId}/profile`;
  }

  function abusePageUrl(type, id) {
    return $filter('formatString')(
      decodeURIComponent(groupDetailsConstants.absoluteUrls.reportAbuse),
      {
        type,
        id,
        absUrl: encodeURIComponent(window.location.href)
      }
    );
  }

  function configureGroupUrl(groupId) {
    return `${EnvironmentUrls.websiteUrl}/${groupsConstants.urlBase}/configure?id=${groupId}`;
  }

  return {
    sendGroupShout,
    profilePageUrl,
    abusePageUrl,
    configureGroupUrl
  };
}

groupModule.factory('groupDetailsService', groupDetailsService);

export default groupDetailsService;
