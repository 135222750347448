import { Endpoints } from 'Roblox';
import groupsModule from '../groupsModule';

const regexGroups = /\/groups/g;
const regexGroupIdFromUrl = /\/(?:groups|communities)\/(\d+)\//g;

function groupUtilityService($filter, urlService, $location) {
  'ngInject';

  return {
    redirectToCommunitiesIfNecessary() {
      const url = $location.absUrl();
      if (regexGroups.test(url)) {
        var communitiesUrl = url.replace(regexGroups, '/communities');

        window.history.replaceState(null, '', communitiesUrl);
        return;
      }
    },
    parseGroupId(url) {
      const match = regexGroupIdFromUrl.exec(url);
      if (match && match.length > 1) {
        return Number(match[1]);
      }
      return null;
    },

    buildGameReferralUrl(placeId) {
      const urls = urlService.getGameDetailReferralUrls();
      const formattedUrl = $filter('formatString')(urls.groupDetail, { placeId });
      return urlService.getAbsoluteUrl(formattedUrl);
    },

    profilePageUrl(userId) {
      return Endpoints
        ? Endpoints.getAbsoluteUrl(`/users/${userId}/profile`)
        : `/users/${userId}/profile`;
    }
  };
}

groupsModule.factory('groupUtilityService', groupUtilityService);
export default groupUtilityService;
